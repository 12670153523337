import React from 'react';
import DescriptionBlock from '../components/DescriptionBlock';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import Image from "gatsby-image";

import { graphql } from "gatsby"
export default function Album({
  data, 
}) {
  const { markdownRemark } = data 
  const { frontmatter, html } = markdownRemark
  return (
    <Layout title={frontmatter.title}>
    <DescriptionBlock title={frontmatter.title} meta={frontmatter.meta}>
      <div dangerouslySetInnerHTML={{ __html: html }} className="description-content"></div>
    </DescriptionBlock>

    <div className="all-photos-container">
    {!frontmatter.photos ? <Loading /> : frontmatter.photos.map((el,i)=>
            <Image key={i} fluid={el.childImageSharp.fluid} style={{
              maxWidth: el.childImageSharp.fluid.aspectRatio * 85 +'vh'
            }} alt={frontmatter.alt ? frontmatter.alt : frontmatter.title} className="album-picture"/>
    )}
    </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        alt
        title
        photos {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
              aspectRatio
            }
          }
        }
        meta {
          person
          time
          location
        }
      }
    }
  }
`